const Preferences = {
  High: 'high',
  Medium: 'medium',
  Low: 'low',
  None: 'none',
};

module.exports = {
  Preferences
};

/* eslint-disable arrow-parens */
import angular from 'angular';
import { localStorage } from '../utils';

const module = angular.module('whyline.controllers.scenarios', [
  'whyline.constants',
]);

const ScenariosController = (
  $state,
  ADMIN_URL,
) => {
  const organizationId = $state.params.organizationId;
  const placeId = $state.params.placeId;
  const token = localStorage.get('token');
  const url = new URL(`${ADMIN_URL}external`);
  url.searchParams.append('token', token);
  url.searchParams.append('url', `/organizations/${organizationId}/places/${placeId}/scenarios`);
  console.log(url.toString());
  location.href = url;
};

module.exports = module.controller('ScenariosController', ScenariosController);

import angular from 'angular';
import '../../services/errors';
import { SubscriptionTypes } from '../../utils/subscriptionTypes';
import { includes } from 'lodash';
import { PriorityCategories } from '../../utils/priorityCategories';
import { Preferences } from '../../utils/preferences';

const module = angular.module('whyline.modals.line-flow-type-organization', [
  'whyline.services.line-flow-type-organization',
  'whyline.services.organization',
  'whyline.services.errors',
]);

const LineFlowTypeOrganizationModalController = ($scope, $uibModalInstance, $timeout, $translate, NotificationService, LineFlowTypeOrganizationService, ErrorService, lineFlowTypeOrganizationId, dialog, $location, OrganizationService) => {
  'ngInject';

  $scope.subscriptionTypes = SubscriptionTypes;

  $scope.priorityCategories = PriorityCategories;

  $scope.preferences = Preferences;

  const organizationId = $location.search().organizationId;

  $scope.useScenarios;

  if (lineFlowTypeOrganizationId) {
    LineFlowTypeOrganizationService.GetOneAsPromiseFromServer(lineFlowTypeOrganizationId)
      .then(lineFlowTypeOrganization => {
        $timeout(() => {
          $scope.lineFlowTypeOrganization = LineFlowTypeOrganizationService.Copy(lineFlowTypeOrganization);
        });
      })
      .catch(ErrorService.handler);
  }

  OrganizationService.GetOneAsPromiseFromServer(organizationId)
    .then(organization => {
      $timeout(() => {
        $scope.useScenarios = organization.configuration.queuer.useScenarios;
        if (!lineFlowTypeOrganizationId) {
          const priorityCategoriesDefaultList = $scope.useScenarios ? [PriorityCategories.Disabled] : [];
          const preferencesDefaultList = $scope.useScenarios ? [Preferences.High] : [];
          $scope.lineFlowTypeOrganization = {
            name: '',
            lineTypeComposition: [
              [SubscriptionTypes.Turn]
            ],
            priorityCategories: [
              priorityCategoriesDefaultList
            ],
            preferences: [
              preferencesDefaultList
            ],
            isWhylineDefault: false,
          };
        }
      });
    })
    .catch(ErrorService.handler);

  $scope.changeHasSecondLineTypeComposition = lineTypeComp => {
    if (lineTypeComp[1]) {
      lineTypeComp.pop();
    } else if (lineTypeComp[0] !== SubscriptionTypes.Turn) {
        lineTypeComp.push(SubscriptionTypes.Turn);
      } else {
        lineTypeComp.push(SubscriptionTypes.Priority);
      }
  };

  $scope.addNewLineTypeComposition = () => {
    $scope.lineFlowTypeOrganization.lineTypeComposition.push([SubscriptionTypes.Turn]);
  };

  $scope.addNewPriorityCategory = () => {
    $scope.lineFlowTypeOrganization.priorityCategories.push([PriorityCategories.Disabled]);
  };

  $scope.addNewPreference = () => {
    $scope.lineFlowTypeOrganization.preferences.push([Preferences.High]);
  };

  $scope.removeLineTypeComposition = index => {
    $scope.lineFlowTypeOrganization.lineTypeComposition.splice(index, 1);
  };

  $scope.removePriorityCategory = index => {
    $scope.lineFlowTypeOrganization.priorityCategories.splice(index, 1);
  };

  $scope.removePreference = index => {
    $scope.lineFlowTypeOrganization.preferences.splice(index, 1);
  };

  $scope.checkRepeated = index => {
    if ($scope.lineFlowTypeOrganization.lineTypeComposition[index][0] === $scope.lineFlowTypeOrganization.lineTypeComposition[index][1]) {
      $scope.lineFlowTypeOrganization.lineTypeComposition[index].pop();
    }
  };

  $scope.close = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.save = () => {
    if (validate()) {
      if (lineFlowTypeOrganizationId) {
        dialog.confirm($translate.instant('call_logic_edit_warning'))
          .then(() => LineFlowTypeOrganizationService.Update($scope.lineFlowTypeOrganization)
              .then(() => {
              $timeout(() => {
                NotificationService.Success($translate.instant('call_logic_update_succ'));
                $uibModalInstance.close('cancel');
              });
            }))
          .catch(ErrorService.handler)
        .catch(() => 'canceled');
      } else {
        dialog.confirm($translate.instant('call_logic_create_warning'))
          .then(() => LineFlowTypeOrganizationService.Create($scope.lineFlowTypeOrganization)
            .then(() => {
              $timeout(() => {
                NotificationService.Success($translate.instant('call_logic_create_succ'));
                $uibModalInstance.close('cancel');
              });
            }))
          .catch(ErrorService.handler)
        .catch(() => 'canceled');
      }
    }
  };

  const validate = () => {
    const name = $scope.lineFlowTypeOrganization.name;

    if (!name) {
      NotificationService.Warning($translate.instant('mandatory_call_logic_name'));
      return false;
    }

    if (name.length < 1 || name.length > 56) {
      NotificationService.Warning($translate.instant('invalid_call_logic_name'));
      return false;
    }

    if (!/^[ '-:A-Za-z\xC0-\xCF\xD1-\xD6\xD8-\xDD\xDF-\xE5\xE7-\xF6\xF8-\xFD\xFF\u0104-\u0107\u010C\u010D\u0116-\u0119\u012E\u012F\u0141-\u0144\u0152\u0160\u0161\u016A\u016B\u0172\u0173\u0178-\u017E]+$/.test(name)) {
      NotificationService.Warning($translate.instant('char_forbidden_name'));
      return false;
    }

    if (!validateUsingAllTypes()) {
      NotificationService.Warning($translate.instant('not_every_call_logic_line_type_being_used'));
      return false;
    }

    if (!validateNotRepeatedTypesInLine()) {
      NotificationService.Warning($translate.instant('call_logic_line_type_repeated'));
      return false;
    }

    if ($scope.useScenarios && !validateUsingAllPriorityCategories()) {
      NotificationService.Warning($translate.instant('mandatory_call_logic_all_priorities'));
      return false;
    }

    if ($scope.useScenarios && !validateNotRepeatedPriorityCategories()) {
      NotificationService.Warning($translate.instant('mandatory_call_logic_repeated_priorities'));
      return false;
    }

    if ($scope.useScenarios && !validateUsingAllPreferencesOrNone()) {
      NotificationService.Warning($translate.instant('mandatory_call_logic_all_preferences'));
      return false;
    }

    return true;
  };

  const validateUsingAllTypes = () => {
    const typesUsed = [];
    let result = false;
    $scope.lineFlowTypeOrganization.lineTypeComposition.forEach(ltc => {
      ltc.forEach(e => {
        if (!includes(typesUsed, e)) {
          typesUsed.push(e);
        }
        if (typesUsed.length === 3) {
          result = true;
        }
      });
    });
    return result;
  };

  const validateNotRepeatedTypesInLine = () => {
    let result = true;
    $scope.lineFlowTypeOrganization.lineTypeComposition.forEach(ltc => {
      if (ltc[0] === ltc[1]) {
        result = false;
      }
    });
    return result;
  };

  const validateNotRepeatedPriorityCategories = () => {
    const finalListPriorityCategories = $scope.lineFlowTypeOrganization.priorityCategories.map(pc => pc[0]);
    const listWithoutRepeated = new Set(finalListPriorityCategories);
    return finalListPriorityCategories.length === listWithoutRepeated.size;
  };

  const validateUsingAllPriorityCategories = () => {
    const priorityCategoriesUsed = [];

    $scope.lineFlowTypeOrganization.priorityCategories.forEach(lpc => {
      if (!priorityCategoriesUsed.includes(lpc[0])) {
        priorityCategoriesUsed.push(lpc[0]);
      }
    });

    return priorityCategoriesUsed.length === Object.keys($scope.priorityCategories).length;
  };

  const validateUsingAllPreferencesOrNone = () => {
    const preferencesUsed = [];

    $scope.lineFlowTypeOrganization.preferences.forEach(lp => {
      if (!preferencesUsed.includes(lp[0])) {
        preferencesUsed.push(lp[0]);
      }
    });

    const allPreferences = preferencesUsed.length === Object.keys($scope.preferences).length;
    const noPreferences = $scope.lineFlowTypeOrganization.preferences.length === 0;
    const response = allPreferences || noPreferences;

    return response;
  };
};

module.exports = module.controller('LineFlowTypeOrganizationModalController', LineFlowTypeOrganizationModalController);

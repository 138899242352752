import angular from 'angular';
import { localStorage } from '../../utils';
import { get } from 'lodash';
import '../../app.constants';
import '../../services/globals';
import '../../services/organization';
import '../../services/errors';
import '../modals/organizations';

const module = angular.module('whyline.controllers.organizations.list', [
  'whyline.constants',
  'whyline.modals.organization',
]);

const OrganizationsListController = (
  $scope,
  $state,
  $uibModal,
  ADMIN_URL,
) => {

  $scope.edit = organizationId => {
    $uibModal.open({
      templateUrl:
        '/templates/components/modals/organizations-modal/index.html',
      size: 'lg',
      controller: 'OrganizationModalController',
      resolve: {
        organizationId: () => organizationId,
        superAdmin: () => $scope.superAdmin,
      },
    }).closed.then(() => {
      $scope.goToNewFront();
    });
  };

  $scope.goToNewFront = () => {
    const token = localStorage.get('token');
    const url = new URL(`${ADMIN_URL}external`);
    url.searchParams.append('token', token);
    url.searchParams.append('url', `/organizations`);
    console.log(url.toString());
    location.href = url;
  };

  if ($state.params.edit) {
    $scope.edit($state.params.edit);
  } else {
    $scope.goToNewFront();
  }
};

export default module.controller(
  'OrganizationsListController',
  OrganizationsListController
);


import '../services/access-control';

module.exports = ($stateProvider, $urlRouterProvider, $stateParams, PlaceService, OrganizationService) => {
  const placeResolver = ['PlaceService', function (PlaceService) {
    return PlaceService.GetCurrentAsPromise();
  }];

  const organizationResolver = ['OrganizationService', function (OrganizationService) {
    return OrganizationService.GetCurrentAsPromise();
  }];

  const userResolver = ['UserService', function (UserService) {
    return UserService.GetCurrentAsPromise();
  }];

  const appOnEnter = ['$state', 'userLogged', function ($state, userLogged) {
    if (!userLogged) {
      $state.go('sign-in', {}, { location: 'replace' });
    }
  }];

  const navBarLeftOnEnter = ['$rootScope', $rootScope => {
    $rootScope.navBarLeftFloat = true;
    $rootScope.navBarLeftCollapsed = true;
    $rootScope.hideLockNavBar = true;
  }];

  const navBarLeftOnExit = ['$rootScope', $rootScope => {
    $rootScope.hideLockNavBar = false;
    if ($rootScope.lockNavBarLeft) {
      $rootScope.navBarLeftFloat = false;
      $rootScope.navBarLeftCollapsed = false;
    }
  }];

  $stateProvider
    //
    // APP (abstract)
    .state('app', {
      abstract: true,
      url: '/app',
      templateUrl: '/templates/views/app.html',
      controller: 'MainController',
      resolve: {
        userLogged: userResolver,
        currentPlace: placeResolver
      },
      onEnter: appOnEnter
    })

    // Organizations
    .state('app.organizations', {
      abstract: true,
      url: '/organizations',
      templateUrl: '/templates/views/organizations/organizations.html',
      controller: 'OrganizationsController',
    })

    .state('app.organizations.list', {
      url: '/list?edit',
      templateUrl: '/templates/views/organizations/organizations.list.html',
      controller: 'OrganizationsListController',
    })

    .state('app.organizations.create', {
      url: '/create',
      templateUrl: '/templates/views/organizations/organizations.create.html',
      controller: 'OrganizationsCreateController',
    })

    //QR codes generator
    .state('app.qr-generator', {
      url: '/qr-generator?organizationId&placeId',
      templateUrl: '/templates/views/qr-generator.html',
      controller: 'QRController',
      controllerAs: 'ctrl',
    })

    .state('app.places', {
      abstract: true,
      url: '/places?organizationId',
      templateUrl: '/templates/views/places/places.html',
      controller: 'PlacesController',
    })

    .state('app.places.list', {
      url: '/list',
      templateUrl: '/templates/views/places/places.list.html',
      controller: 'PlacesListController',
    })

    .state('app.places.edit', {
      url: '/edit/:id',
      templateUrl: '/templates/views/places/places.edit.html',
      controller: 'PlacesEditController',
    })

    .state('app.places.create', {
      url: '/create/',
      templateUrl: '/templates/views/places/places.edit.html',
      controller: 'PlacesEditController',
    })

    // Now serving
    .state('app.now-serving', {
      url: '/now-serving?organizationId&placeId',
      templateUrl: '/templates/views/now-serving.html',
      controller: 'NowServingController',
      params: {
        permission: 'section.now-serving',
        selectBox: null,
      },
      resolve: {
        currentOrganization: organizationResolver,
      },
    })

    .state('app.now-serving.queue', {
      url: '/queue?areaId&lineId',
      templateUrl: '/templates/views/now-serving/queue.html',
      controller: 'QueueController',
    })

    // RECEPTION
    .state('app.reception', {
      abstract: true,
      url: '/reception?organizationId&placeId',
      templateUrl: '/templates/views/reception/index.html',
      controller: 'ReceptionController',
      params: {
        permission: 'section.reception',
      },
    })

    .state('app.reception.welcome', {
      url: '/welcome',
      templateUrl: '/templates/views/reception/welcome.html',
      controller: 'ReceptionWelcomeController',
      params: {
        selectBox: null,
      },
      resolve: {
        canEnter: ['$q', 'PlaceService', canMerchantEnter],
        currentPlace: placeResolver,
        currentOrganization: organizationResolver,
      },
    })

    // Reception appointment
    .state('app.reception.appointment', {
      url: '/appointment',
      templateUrl: '/templates/views/appointment/reception/search.html',
      controller: 'ReceptionAppointmentSearchController',
      params: {
        fields: null
      }
    })

    .state('app.reception.appointment-results', {
      url: '/appointment/results',
      templateUrl: '/templates/views/appointment/reception/results.html',
      controller: 'ReceptionAppointmentResultsController',
      params: {
        fields: null
      }
    })

    .state('app.reception.appointment-confirm', {
      url: '/appointment/confirm?id&type',
      templateUrl: '/templates/views/appointment/reception/confirm.html',
      controller: 'ReceptionAppointmentConfirmController'
    })

    .state('app.reception.step', {
      url: '/step/:n',
      templateUrl: '/templates/views/reception/list.html',
      controller: 'ReceptionPathController',
      params: {
        rejoining: false
      }
    })

    .state('app.reception.status', {
      url: '/status?areaId&lineId',
      templateUrl: '/templates/views/reception/status.html',
      controller: 'ReceptionStatusController',
      params: {
        rejoining: false
      }
    })

    // Lines
    .state('app.lines', {
      url: '/lines?organizationId&placeId',
      templateUrl: '/templates/views/lines.html',
      controller: 'LinesController',
      resolve: {
        currentPlace: placeResolver
      },
      params: {
        permission: 'section.lines'
      }
    })

    // Roles
    .state('app.roles', {
      url: '/roles?organizationId&placeId',
      templateUrl: '/templates/views/roles.html',
      controller: 'RolesController',
      params: {
        permission: 'section.roles',
      }
    })

    // Resource Groups
    .state('app.resource-groups', {
      url: '/resource-groups?organizationId&placeId',
      templateUrl: '/templates/views/resource-groups.html',
      controller: 'ResourceGroupsController',
      params: {
        permission: 'section.resource-groups',
      }
    })

    // Sectors
    .state('app.sectors', {
      url: '/organization/sectors?organizationId',
      templateUrl: '/templates/views/sectors.html',
      controller: 'SectorsController',
      params: {
        permission: 'section.sectors',
      },
    })

    // OrganizationAreas
    .state('app.organization-areas', {
      url: '/organization/areas?organizationId',
      templateUrl: '/templates/views/organization-areas.html',
      controller: 'OrganizationAreasController',
      params: {
        permission: 'section.organization-areas',
      },
    })

    // OrganizationLines
    .state('app.organization-lines', {
      url: '/organization/lines?organizationId',
      templateUrl: '/templates/views/organization-lines.html',
      controller: 'OrganizationLinesController',
      params: {
        permission: 'section.organization-lines',
      },
      resolve: {
        currentOrganization: organizationResolver
      },
    })

    // OrganizationCustomRole
    .state('app.organization-custom-role', {
      url: '/organization/custom-role?organizationId',
      templateUrl: '/templates/views/organization-custom-roles.html',
      controller: 'OrganizationCustomRoleController',
      params: {
        permission: 'section.organization-custom-role',
      },
      resolve: {
        currentOrganization: organizationResolver
      },
    })

    // Organization Conditions
    .state('app.organization-conditions', {
      url: '/organization/conditions?organizationId',
      templateUrl: '/templates/views/organization-conditions.html',
      controller: 'OrganizationConditionsController',
      params: {
        permission: 'section.organization-conditions',
      },
      resolve: {
        currentOrganization: organizationResolver
      },
    })

    // Organization Scenarios
    .state('app.organization-scenarios', {
      url: '/organization/scenarios?organizationId',
      templateUrl: '/templates/views/organization-scenarios.html',
      controller: 'OrganizationScenariosController',
      params: {
        permission: 'section.organization-scenarios',
      },
      resolve: {
        currentOrganization: organizationResolver
      },
    })

    // Place Scenarios
    .state('app.scenarios', {
      url: '/scenarios?organizationId&placeId',
      templateUrl: '/templates/views/scenarios.html',
      controller: 'ScenariosController',
      params: {
        permission: 'section.scenarios',
      },
      resolve: {
        currentOrganization: organizationResolver,
        currentPlace: placeResolver,
      },
    })

    // CallLogics
    .state('app.call-logics', {
      url: '/organization/call-logics?organizationId',
      templateUrl: '/templates/views/line-flow-type-organization.html',
      controller: 'LineFlowTypeOrganizationController',
      params: {
        permission: 'section.call-logics',
      },
    })

    // OrganizationUsers
    .state('app.organization-users', {
      url: '/organization/users?organizationId',
      templateUrl: '/templates/views/organization-users.html',
      controller: 'OrganizationUsersController',
      params: {
        permission: 'section.organization-users',
      },
      resolve: {
        currentOrganization: organizationResolver,
      },
    })

    // Areas
    .state('app.areas', {
      url: '/areas?organizationId&placeId',
      templateUrl: '/templates/views/areas.html',
      controller: 'AreasController',
      params: {
        permission: 'section.areas',
      },
      resolve: {
        canEnter: ['$q', 'PlaceService', canMerchantEnter]
      }
    })

    // Schemas
    .state('app.schemas', {
      url: '/schemas?organizationId&placeId',
      templateUrl: '/templates/views/schemas.html',
      controller: 'SchemasController',
      params: {
        permission: 'section.sectors'
      },
      resolve: {
        canEnter: ['$q', 'PlaceService', canMerchantEnter]
      }
    })

    // Place Sectors
    .state('app.placeSectors', {
      url: '/place-sectors?organizationId&placeId',
      templateUrl: '/templates/views/sectors.html',
      controller: 'SectorsController',
      resolve: {
        currentPlace: placeResolver,
      },
      params: {
        permission: 'section.sectors',
      },
    })

    // Place CallLogics
    .state('app.place-call-logics', {
      url: '/place-call-logics?organizationId&placeId',
      templateUrl: '/templates/views/line-flow-type-organization.html',
      controller: 'LineFlowTypeOrganizationController',
      params: {
        permission: 'section.call-logics',
      },
    })

    // Totems
    .state('app.totems', {
      url: '/totems?organizationId&placeId',
      templateUrl: '/templates/views/totems.html',
      controller: 'TotemsController',
      resolve: {
        currentPlace: placeResolver
      },
      params: {
        permission: 'section.totems',
      },
    })

    // Devices
    .state('app.devices', {
      url: '/devices?organizationId&placeId',
      templateUrl: '/templates/views/devices.html',
      controller: 'DevicesController',
      resolve: {
        currentPlace: placeResolver,
      },
      params: {
        permission: 'section.devices',
      },
    })

    // Plans
    .state('app.plans', {
      url: '/plans',
      templateUrl: '/templates/views/plans.html',
      controller: 'PlansController',
      params: {
        permission: 'section.plans',
      },
    })

    // Displays
    .state('app.displays', {
      url: '/displays?organizationId&placeId',
      templateUrl: '/templates/views/displays.html',
      controller: 'DisplaysController',
      resolve: {
        currentPlace: placeResolver
      },
      params: {
        permission: 'section.displays'
      }
    })

    // Banners
    .state('app.banners', {
      url: '/banners?organizationId&placeId',
      templateUrl: '/templates/views/banners.html',
      controller: 'BannersController',
      resolve: {
        currentPlace: placeResolver
      },
      params: {
        permission: 'section.banners'
      }
    })

    // Users
    .state('app.users', {
      url: '/users?organizationId&placeId',
      templateUrl: '/templates/views/users.html',
      controller: 'UsersController',
      params: {
        permission: 'section.users'
      }
    })

    // Boxes
    .state('app.boxes', {
      url: '/boxes?organizationId&placeId',
      templateUrl: '/templates/views/boxes.html',
      controller: 'BoxesController',
      params: {
        permission: 'section.boxes'
      }
    })

    // Stats
    .state('app.statistics', {
      url: '/stats?organizationId&placeId',
      templateUrl: '/templates/views/stats.html',
      controller: 'StatsController',
      params: {
        permission: 'section.statistics'
      }
    })


    // Schedule
    .state('app.schedule', {
      abstract: true,
      url: '/schedule?organizationId&placeId',
      templateUrl: '/templates/views/schedule.html',
      controller: 'ScheduleController',
      resolve: {
        canGiveAppointments: ['$q', '$stateParams', 'PlaceService', placeConfigResolver],
      },
      params: {
        permission: 'section.schedule',
        checkConfig: 'canGiveAppointments'
      },
      redirectTo: 'app.schedule.management',
      onEnter: navBarLeftOnEnter,
      onExit: navBarLeftOnExit,
    })

    .state('app.schedule.management', {
      url: '/management',
      templateUrl: '/templates/views/schedule/management.html',
    })

    .state('app.schedule.holidays', {
      url: '/holidays',
      params: {
        permission: 'section.holidays'
      },
      templateUrl: '/templates/views/schedule/holidays.html',
      controller: 'ScheduleHolidaysController',
    })

    .state('app.schedule.search', {
      abstract: true,
      url: '/search',
      templateUrl: '/templates/views/schedule/search.html',
      controller: 'ReceptionController',
      params: {
        fields: null,
        searchFrom: 'agenda'
      }
    })

    // Reception appointment
    .state('app.schedule.search.filters', {
      url: '/filters',
      templateUrl: '/templates/views/appointment/reception/search.html',
      controller: 'ReceptionAppointmentSearchController',
      params: {
        fields: null,
        searchFrom: 'agenda'
      }
    })

    .state('app.schedule.search.appointment-results', {
      url: '/results',
      templateUrl: '/templates/views/appointment/reception/results.html',
      controller: 'ReceptionAppointmentResultsController',
      params: {
        fields: null,
        searchFrom: 'agenda'
      }
    })

    .state('app.schedule.search.appointment-confirm', {
      url: '/confirm?id&type',
      templateUrl: '/templates/views/appointment/reception/confirm.html',
      controller: 'ReceptionAppointmentConfirmController'
    })

    //
    // Outside APP (abstract)
    // TotemTV
    .state('totem-tv', {
      abstract: true,
      url: '/totem?placeId&organizationId&totemId',
      templateUrl: '/templates/views/totem-tv.html',
      controller: 'TotemTvController',
    })
    .state('totem-tv.welcome', {
      url: '/welcome',
      templateUrl: '/templates/views/totem-tv/welcome.html',
      controller: 'TotemTvWelcomeAndThanksController',
      resolve: {
        currentPlace: placeResolver
      },
      params: {
        postMessage: null,
      }
    })

    // totem appointment
    // Reception appointment
    .state('totem-tv.appointment', {
      url: '/appointment',
      templateUrl: '/templates/views/appointment/totem/search.html',
      controller: 'TotemTvAppointmentSearchController',
    })

    .state('totem-tv.appointment-results', {
      url: '/appointment/results',
      templateUrl: '/templates/views/appointment/totem/results.html',
      controller: 'TotemTvAppointmentResultsController',
      params: {
        fields: null,
      },
    })

    .state('totem-tv.appointment-confirm', {
      url: '/appointment/confirm?appointmentId',
      templateUrl: '/templates/views/appointment/totem/confirm.html',
      controller: 'TotemTvAppointmentConfirmController',
    })

    .state('totem-tv.step', {
      url: '/step/:n',
      templateUrl: '/templates/views/totem-tv/list.html',
      controller: 'TotemTvPathController',
    })
    .state('totem-tv.status', {
      url: '/status',
      templateUrl: '/templates/views/totem-tv/status.html',
      controller: 'TotemTvStatusController',
    })
    .state('totem-tv.user', {
      url: '/user/:step',
      templateUrl: '/templates/views/totem-tv/user.html',
      controller: 'TotemTvUserController',
      resolve: {
        currentOrganization: organizationResolver,
      }
    })
    .state('totem-tv.thanks', {
      url: '/thanks/:name',
      templateUrl: '/templates/views/totem-tv/thanks.html',
      controller: 'TotemTvWelcomeAndThanksController',
      resolve: {
        currentPlace: placeResolver
      }
    })

    // DisplayTV
    .state('display-tv', {
      url: '/display?placeId&organizationId&displayId&fontSize',
      templateUrl: '/templates/views/display-tv.html',
      controller: 'DisplayTvController',
      resolve: {
        nothing: ['whylineBlockUIConfig', 'DisplaySocket', function (whylineBlockUIConfig, DisplaySocket) {
          whylineBlockUIConfig.set(false);

          return whylineBlockUIConfig;
        }]
      }
    })

    // Account
    .state('sign-in', {
      url: '/sign-in',
      templateUrl: '/templates/views/account/sign-in.html',
      controller: 'SignInController'
    })
    .state('sign-up', {
      url: '/sign-up?placeId',
      templateUrl: '/templates/views/account/sign-up.html',
      controller: 'SignUpController',
    })
    .state('recover-password', {
      url: '/recover-password?app',
      templateUrl: '/templates/views/account/recover.html',
      controller: 'RecoverPasswordController as vm',
    })
    .state('reset-password', {
      url: '/reset-password?token',
      templateUrl: '/templates/views/account/reset.html',
      controller: 'ResetPasswordController as vm',
    })
    .state('verify-account', {
      url: '/verify-account?token',
      templateUrl: '/templates/views/account/verify.html',
      controller: 'VerifyAccountController as vm',
    })

    /**
     * File uploader for Sigeci
     */
    .state('app.appointments', {
      url: '/appointments',
      templateUrl: '/templates/views/appointments.html',
      controller: 'UploaderController',
      params: {
        permission: 'section.appointments',
      },
      onEnter: navBarLeftOnEnter,
      onExit: navBarLeftOnExit,
    })

    // Redirect v1 to v2
    .state('v1tov2', {
      url: '/v1tov2',
      templateUrl: '/templates/views/v1tov2.html',
      controller: 'V1toV2Controller',
      resolve: {
        currentOrganization: organizationResolver,
      },
    });

  function canMerchantEnter($q, PlaceService) {
    var deferred = $q.defer();

    PlaceService
      .GetCurrentAsPromise()
      .then(place => {
        const isFree = place && place.plan && place.plan.type && place.plan.type === 'free';

        if (isFree && place.enterpriseType === 'whyline') {
          deferred.reject();
        } else {
          deferred.resolve();
        }
      });

    return deferred.promise;
  }

  /**
   * This function allows the route to check a place configuration.
   * The param 'checkConfig' defines the configuration you want to check.
   * Example --> params : { checkConfig: 'canGiveAppointments' }
   */
  function placeConfigResolver($q, $stateParams, PlaceService) {
    var deferred = $q.defer();
    const config = $stateParams.checkConfig;
    PlaceService.GetCurrentAsPromise().then(place => {
      if (!place.configuration[config]) {
        deferred.reject();
      } else {
        deferred.resolve();
      }
    });
    return deferred.promise;
  }
  $urlRouterProvider.otherwise('/app/organizations/list');
};

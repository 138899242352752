import angular from 'angular';
import '../resources/line-flow-type-organization';
import { cloneDeep } from 'lodash';

const module = angular.module('whyline.services.line-flow-type-organization', [
  'whyline.resources.line-flow-type-organization'
]);

let LineFlowTypeOrganization;
// const socketEvents = {
//   create: 'organizationLine:created',
//   update: 'organizationLine:updated',
//   deleted: 'organizationLine:deleted',
//   clear: 'organizationLine:clear',
// };
class LineFlowTypeOrganizationService {
  static $inject = ['LineFlowTypeOrganization'];

  constructor(injectedLineFlowTypeOrganization) {
    LineFlowTypeOrganization = injectedLineFlowTypeOrganization;
  }

  Create(data) {
    const newLineFlowTypeOrganization = new LineFlowTypeOrganization(data);
    return LineFlowTypeOrganization.Save(newLineFlowTypeOrganization);
  }

  Copy(lineFlowTypeOrganization) {
    const copy = cloneDeep(lineFlowTypeOrganization);
    return new LineFlowTypeOrganization(copy);
  }

  Enable(lineFlowTypeOrganizationId) {
    const lineFlowTypeOrganizationToRemove = LineFlowTypeOrganization.FindById(lineFlowTypeOrganizationId);
    return LineFlowTypeOrganization.Enable(lineFlowTypeOrganizationToRemove);
  }

  EnableInPlace(lineFlowTypeOrganizationId) {
    const lineFlowTypeOrganizationToRemove = LineFlowTypeOrganization.FindById(lineFlowTypeOrganizationId);
    return LineFlowTypeOrganization.EnableInPlace(lineFlowTypeOrganizationToRemove);
  }

  GetAll() {
    return LineFlowTypeOrganization.FetchAll();
  }

  GetAllByOrganization(organizationId) {
    return LineFlowTypeOrganization.GetAllByOrganization(organizationId);
  }

  GetAllEnabledByOrganization(organizationId, fromPlace) {
    return LineFlowTypeOrganization.GetAllEnabledByOrganization(organizationId, fromPlace);
  }

  GetAllEnabledByPlace(placeId, fromOrganization) {
    return LineFlowTypeOrganization.GetAllEnabledByPlace(placeId, fromOrganization);
  }

  GetAllFromServer() {
    return LineFlowTypeOrganization.FetchAll({force: true});
  }

  GetAllAsPromise() {
    return LineFlowTypeOrganization.FetchAll({ promise: true });
  }

  GetAllAsPromiseFromServer() {
    return LineFlowTypeOrganization.FetchAll({ promise: true, force: true});
  }

  GetOne(lineFlowTypeOrganizationId) {
    return LineFlowTypeOrganization.FindById(lineFlowTypeOrganizationId);
  }

  GetOneFromServer(lineFlowTypeOrganizationId) {
    return LineFlowTypeOrganization.FindById(lineFlowTypeOrganizationId, {force: true});
  }

  GetOneAsPromiseFromServer(lineFlowTypeOrganizationId) {
    return LineFlowTypeOrganization.FindById(lineFlowTypeOrganizationId, {promise: true, force: true});
  }

  GetOneAsPromise(lineFlowTypeOrganizationId) {
    return LineFlowTypeOrganization.FindById(lineFlowTypeOrganizationId, {promise: true});
  }

  FindByIds(ids, options) {
    return LineFlowTypeOrganization.FindByIds(ids, options);
  }

  Update(lineFlowTypeOrganization) {
    return LineFlowTypeOrganization.Save(lineFlowTypeOrganization);
  }

  Remove(lineFlowTypeOrganizationId) {
    const lineFlowTypeOrganizationToRemove = LineFlowTypeOrganization.FindById(lineFlowTypeOrganizationId);
    return LineFlowTypeOrganization.Remove(lineFlowTypeOrganizationToRemove);
  }
}

module.exports = module.service('LineFlowTypeOrganizationService', LineFlowTypeOrganizationService);
